import { fade, makeStyles } from '@material-ui/core/styles/';

export default makeStyles(theme => ({
  root: {
    margin: 5,
    background: theme.palette.primary.mainBGColor,
    '&:hover': {
      background: fade(theme.palette.primary.mainBGColor, .9)
    }
  }
}));