import React from 'react';
import styles from './styles';

import Layout from '../../components/layout';
import Title from '../../components/Title/index';
import CustomButton from '../../components/CustomButton/customButton';
import GoBackButton from '../../components/Icons/GoBackIcon/index'
import RichTextRendererFromAPI from '../../components/RichTextRenderer/FromAPI/index';
import { addProtocol, formatDateToFrenchFormat, formatHour } from '../../utils/format';

import { Box, Typography } from '@material-ui/core';

const TrainingSessionDetails = (props) => {
  const { state } = props.location
  const classes = styles();

  const date = state?.fields?.date || state?.date;
  const id = state?.sys.id;
  const author = state?.fields?.name || state?.author;
  const image = state?.fields?.image.fields.file.url || state?.image;
  const description = state?.fields?.description || state?.description;
  const localizedDate = formatDateToFrenchFormat(date) + " à " + formatHour(date);

  return (
    <Layout>
      <Title title={state?.fields?.title} />
      <Box className={classes.container}>
        {/* Left container for picture  */}
        <Box className={classes.leftContainer}>
          <img src={image} alt='formation' />
        </Box>
        {/* Right container for text and buttons */}
        <Box className={classes.rightContainer}>
          {/* Container for date and author */}
          <Box className={classes.subtitlesContainer}>
            <Typography className={classes.subtitle1} component='h2'>Le {localizedDate}</Typography>
            <Typography className={classes.subtitle2} component='h2'>Par {author}</Typography>
          </Box>
          {/* Container for text */}
          <RichTextRendererFromAPI className={classes.text} details={description} />
          {/* Container for icons */}
          <Box className={classes.buttonContainer}>
            {/* <GoBackIcon className={classes.goBackButton} /> */}
            <Box className={classes.goBackButton}>
              <GoBackButton />
            </Box>
            <Box className={classes.subscribeButton}>
              <CustomButton
                text="JE M'INSCRIS"
                buttonStyle="secondaryButton"
                link={`${addProtocol(process.env.GATSBY_ADHERENT_URL, "https")}/formations/${id}`}
                isOutsideLink={true}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Layout>

  );
}

export default TrainingSessionDetails
