import React from 'react'
import PropTypes from 'prop-types';
import { navigate } from 'gatsby'
import { Grid } from '@material-ui/core';
import ArrowBackSharpIcon from '@material-ui/icons/ArrowBackSharp';
import CustomIconButton from '../../CustomIconButton';

const GoBackIcon = ({ action }) => {

  return (
    <Grid container justify="center">
      <CustomIconButton action={action}>
        <ArrowBackSharpIcon color="secondary" fontSize="large" />
      </CustomIconButton>
    </Grid>
  )
}

export default GoBackIcon

GoBackIcon.propTypes = {
  action: PropTypes.func
};

GoBackIcon.defaultProps = {
  action: () => navigate(-1)
};
