import React from 'react'
import PropTypes from 'prop-types';
import { IconButton } from '@material-ui/core';
import useStyles from './styles';

const CustomIconButton = ({ children, action }) => {
  const classes = useStyles();

  return (
    <IconButton className={classes.root} onClick={action}>
      {children}
    </IconButton>
  )
}

export default CustomIconButton

CustomIconButton.propTypes = {
  children: PropTypes.node.isRequired,
  action: PropTypes.oneOfType([PropTypes.func, PropTypes.string])
};