import React from "react"
import { Router } from "@reach/router"

import TrainingSessionDetails from '../../ClientPages/TrainingSessionDetails';

const App = () => {
  return (
    <Router basepath="/">
      <TrainingSessionDetails path="/session/:id" component={TrainingSessionDetails} />
    </Router>
  )
}
export default App
