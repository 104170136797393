import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles(theme => ({
  container: {
    margin: '2%',
    display: 'flex',
    '@media (max-width:890px)': {
      display: 'block'
    },
  },
  leftContainer: {
    width: '50%',
    marginRight: '3%',
    '@media (max-width:890px)': {
      width: '90%',
      marginRight: 0
    },
  },
  rightContainer: {
    width: '50%',
    marginRight: '5%',
    '@media (max-width:890px)': {
      width: '100%',
      marginRight: '1%',
      marginLeft: '1%'
    },
  },
  subtitlesContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: '1%',
    paddingBottom: '3%',
    '@media (max-width:890px)': {
      flexDirection: 'column'
    },
  },
  subtitle1: {
    fontWeight: 600,
    fontSize: 20,
    '@media (max-width:890px)': {
      fontWeight: 500,
      fontSize: 14
    },
  },
  subtitle2: {
    fontWeight: 600,
    fontSize: 20,
    textDecoration: 'underline',
    '@media (max-width:890px)': {
      fontWeight: 500,
      fontSize: 14
    },
  },
  text: {
    textAlign: 'justify',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    '@media (max-width:890px)': {
      display: 'block',
      textAlign: 'center'
    },
  },
  goBackButton: {
    width: '10%',
    '@media (max-width:890px)': {
      width: '100%'
    },
  },
  subscribeButton: {
    marginTop: '2%',
    marginLeft: '5%',
    '@media (max-width:1125px)': {
      marginTop: '3%'
    },
    '@media (max-width:890px)': {
      marginLeft: 0
    },
  },

}));